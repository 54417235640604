<script>
import errorsMixin from '@/mixins/errors.mixin'

export default {
  render() {
    return this.$scopedSlots.default({
      processing: this.processing,
      update: this.update,
      showing: this.showing,
      toggle: this.toggle
    })
  },
  
  props: {
    ID: {
      type: Number,
      required: true
    }
  },
  
  inject: ['svc'],
  
  mixins: [errorsMixin],
  
  data: () => ({
    processing: false,
    showing: false
  }),
  
  methods: {
    updateShowing(val) {
      this.showing = val
    },
    
    toggle() {
      this.showing = !this.showing
    },
    
    async update(data) {
      try {
        this.processing = true
        let res = await this.svc.updateCourseLocations(this.ID, data)
        this.$notify({text: 'Updated', type: 'success'})
        this.toggle()
      } catch (error) {
        this.processError(error, {redirectName: this.$ROUTER_NAMES.LOGIN_CRM})
      } finally {
        this.processing = false
      }
    }
  }
}
</script>